import Bubble from '@trustblock/components/standalone/Bubble/Bubble';
import InputText from '@trustblock/components/standalone/Inputs/InputText/InputText';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SearchProjectsResponse } from '@trustblock/types/project.types';
import ProjectMini from '@trustblock/components/global/ProjectMini/ProjectMini';
import useSearchProjects from '@trustblock/web/hooks/project/useSearchProjects';
import Pagination from '@trustblock/components/standalone/Pagination/Pagination';
import { zodResolver } from '@hookform/resolvers/zod';
import { searchProjectsValidator } from '@trustblock/validators/project.validators';
import { z } from 'zod';
import Form from '@trustblock/components/standalone/Form/Form';
import Button from '@trustblock/components/standalone/Button/Button';
import { useSearchParams } from 'next/navigation';
import { nullsToUndefined } from '@trustblock/api/utils/dataTransformers';
import styles from './HomePage.module.scss';

const searchProjectsValidatorLocal = searchProjectsValidator.omit({ page: true });
export type SearchProjectsParamsLocal = z.infer<typeof searchProjectsValidatorLocal>;

interface HomePageProjectsProps {
  projectsSearch?: SearchProjectsResponse;
  onChange: (data: SearchProjectsParamsLocal & { page: number }) => void;
  isActive: boolean;
}

export default function HomePageProjects({ projectsSearch, onChange, isActive }: HomePageProjectsProps) {
  const query = useSearchParams();
  const { register, handleSubmit, getValues, setValue } = useForm<SearchProjectsParamsLocal>({
    mode: 'onTouched',
    resolver: zodResolver(searchProjectsValidatorLocal)
  });
  const { currentPage, projects, searchProjects, setCurrentPage, totalPages, error, isRequesting } = useSearchProjects({
    initialData: projectsSearch
  });

  useEffect(() => {
    if (!query) return;

    if (query.get('page')) setCurrentPage(Number(query.get('page')));
    const searchTerm = query.get('searchTerm') ?? undefined;
    setValue('searchTerm', searchTerm);
  }, [query, setValue, setCurrentPage]);

  const onChangeWrapper = (page?: number) => {
    const dataToSend = nullsToUndefined({
      ...getValues(),
      page: page ?? currentPage
    });
    onChange(dataToSend);
  };

  const setCurrentPageWrapper = async (page: number) => {
    setCurrentPage(page);
    await searchProjects({ page, ...getValues() });
    onChangeWrapper(page);
  };

  const search = handleSubmit(async (data) => {
    await searchProjects(data);
    onChangeWrapper();
  });

  return (
    <section className={classNames(styles.homePageSection, { [styles.homePageSectionActive]: isActive })}>
      <Form className={styles.homePageSearchProject} onSubmit={search}>
        <InputText
          placeholder="Type a url, domain, name or contract address"
          fieldName="searchTerm"
          icon="Search"
          error={error}
          register={register}
        />
        <Button type="submit" loading={isRequesting} icon="Search" onlyIcon fill mode="primary" />
      </Form>
      <div
        className={classNames(styles.homePageResult, styles.homePageResultProject)}
        data-testid="homepage-projects-results"
      >
        {projects && projects?.length !== 0 ? (
          projects.map((project) => (
            <ProjectMini key={project.slug} project={project} isForHomepage data-testid="homepage-project-mini" />
          ))
        ) : (
          <Bubble data-testid="homepage-no-projects" header="No projects found." icon="Info" fill />
        )}
      </div>
      <div className={styles.homePagePagination}>
        <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={setCurrentPageWrapper} />
      </div>
    </section>
  );
}
