import Tag from '@trustblock/components/standalone/Tag/Tag';
import { formatDate } from '@trustblock/helpers/formatters';
import Link from 'next/link';
import React from 'react';
import { ContractsCount } from '@trustblock/types/contract.types';
import classNames from 'classnames';
import styles from './ProjectMini.module.scss';
import TagChains from '../TagChains/TagChains';

export interface ProjectMiniProps {
  project: {
    domain: string;
    slug: string;
    name: string;
    contractsCount: ContractsCount;
    lastAuditDate: number;
    hasCriticalIssuesNotFixed: boolean;
    lastAuditorName: string;
  };
  mode?: 'auto' | 'line' | 'block';
  isForHomepage?: boolean;
}

export default function ProjectMini({ project, mode = 'auto', isForHomepage = false, ...props }: ProjectMiniProps) {
  return (
    <Link
      {...props}
      href={`/project/${project.slug}`}
      className={classNames(styles.tbProjectMini, {
        [styles.tbProjectMiniAuto]: mode === 'auto',
        [styles.tbProjectMiniBlock]: mode === 'block',
        [styles.tbProjectMiniLine]: mode === 'line',
        [styles.tbProjectMiniHomepage]: isForHomepage
      })}
    >
      {isForHomepage && <div />}
      <div className={styles.tbProjectMiniHeader}>
        <div className={styles.tbProjectMiniHeaderNameContainer}>
          <div className={styles.tbProjectMiniFavicon}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={`https://icons.duckduckgo.com/ip3/${project.domain}.ico`} alt="project favicon" />
          </div>
          <span className="tbProjectMiniProjectName">{project.name}</span>
        </div>
        <TagChains contractsCount={project.contractsCount} max={4} />
      </div>
      {project.hasCriticalIssuesNotFixed ? (
        <Tag color="error" icon="AlertTriangle" label="Critical issues reported" />
      ) : (
        <Tag color="valid" icon="CheckCheck" label="No critical issue reported" />
      )}
      {project.lastAuditDate ? (
        <Tag
          color="primary"
          icon="CalendarCheck"
          label={`Audited on ${formatDate(new Date(project.lastAuditDate).toDateString())}`}
          className={styles.tbProjectMiniLastAuditDate}
        />
      ) : (
        <Tag color="error" icon="AlertTriangle" label="No audit found" className={styles.tbProjectMiniLastAuditDate} />
      )}
      <Tag color="grey2" icon="ShieldCheck" label={`by ${project.lastAuditorName}`} />
      {isForHomepage && <div />}
    </Link>
  );
}
