import { SearchAuditorsParams } from '@trustblock/validators/auditor.validators';
import { useCallback, useState } from 'react';
import { SearchAuditorsResponse } from '@trustblock/types/auditor.types';
import { AxiosError } from 'axios';
import { ZodError } from 'zod';
import { HttpStatusCode } from '@trustblock/types/http.types';
import { isValidationErrorResponse } from '@trustblock/helpers/guards';
import { apiRoutes } from '@trustblock/web/helpers/api';
import useApi from '../global/useApi';

export default function useSearchAuditors({ initialData }: { initialData?: SearchAuditorsResponse }) {
  const { request } = useApi();
  const [error, setError] = useState<string | null>(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [auditors, setAuditors] = useState<SearchAuditorsResponse['items'] | undefined>(initialData?.items);
  const [currentPage, setCurrentPage] = useState<number>(initialData?.pagination?.page ?? 1);
  const [totalItems, setTotalItems] = useState<number>(initialData?.pagination?.totalItems ?? 0);
  const [totalPages, setTotalPages] = useState<number>(initialData?.pagination?.totalPages ?? 0);

  const searchAuditors = useCallback(
    async ({ name, tvs, tags, chains, page }: Omit<SearchAuditorsParams, 'page'> & { page?: number }) => {
      setIsRequesting(true);
      try {
        const result = await request<'searchAuditors'>({
          route: apiRoutes.searchAuditors(),
          data: {
            name,
            tvs,
            tags,
            chains,
            page: page ? page.toString() : currentPage.toString()
          }
        });
        setError(null);
        setAuditors(result.data.items);
        setTotalItems(result.data.pagination.totalItems);
        setTotalPages(result.data.pagination.totalPages);
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === HttpStatusCode.NotFound) {
            setAuditors([]);
          } else if (err.response && isValidationErrorResponse(err.response.data)) {
            const firstValidationError = Object.values(err.response.data.validationErrors)[0];
            setError(firstValidationError);
          } else setError((err.response?.data as AxiosError)?.message);
        } else if (err instanceof ZodError) {
          setError(err.message);
        }
      }
      setIsRequesting(false);
    },
    [currentPage, request]
  );

  return {
    searchAuditors,
    auditors,
    error,
    setCurrentPage,
    currentPage,
    totalItems,
    totalPages,
    isRequesting
  };
}
