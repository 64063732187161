import Tag from '@trustblock/components/standalone/Tag/Tag';
import { formatNumber, tryPluralizeWord } from '@trustblock/helpers/formatters';
import Link from 'next/link';
import React from 'react';
import { ProjectsCount } from '@trustblock/types/project.types';
import { ContractsCount } from '@trustblock/types/contract.types';
import classNames from 'classnames';
import styles from './AuditorMini.module.scss';
import TagChains from '../TagChains/TagChains';
import TagCategories from '../TagCategories/TagCategories';

export default function AuditorMini({
  auditor,
  mode = 'auto',
  isForHomepage = false,
  ...props
}: {
  auditor: {
    profilePictureUrl?: string;
    slug: string;
    name: string;
    tvs?: number;
    auditsTotal: number;
    contractsCount: ContractsCount;
    projectsCount: ProjectsCount;
  };
  mode?: 'auto' | 'line' | 'block';
  isForHomepage?: boolean;
}) {
  const hasPublishedAudits = auditor.auditsTotal > 0;

  return (
    <Link
      {...props}
      href={`/auditor/${auditor.slug}`}
      className={classNames(styles.tbAuditorMini, {
        [styles.tbAuditorMiniAuto]: mode === 'auto',
        [styles.tbAuditorMiniBlock]: mode === 'block',
        [styles.tbAuditorMiniLine]: mode === 'line'
      })}
    >
      {isForHomepage && <div />}
      <div className={styles.tbAuditorMiniHeader}>
        <div className={styles.tbAuditorMiniHeaderNameContainer}>
          {auditor.profilePictureUrl ? (
            <div className={styles.tbAuditorMiniProfilePicture}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={auditor.profilePictureUrl} width={24} height={24} alt="auditor logo" />
            </div>
          ) : (
            <div className={styles.tbAuditorMiniProfilePictureNotFound}>
              <span>{auditor.name[0].toUpperCase()}</span>
            </div>
          )}
          <span>{auditor.name}</span>
        </div>
        {hasPublishedAudits && <TagChains contractsCount={auditor.contractsCount} max={4} />}
      </div>
      {hasPublishedAudits && auditor.tvs ? (
        <Tag
          color={auditor?.tvs !== 0 ? 'valid' : 'grey2'}
          icon={auditor?.tvs !== 0 ? 'DollarSign' : 'XCircle'}
          label={auditor?.tvs !== 0 ? `${formatNumber(auditor?.tvs ?? 0)} TVS` : 'No metrics yet'}
        />
      ) : (
        <Tag color="grey2" icon="XCircle" label="No metrics yet" />
      )}
      <Tag
        color="primary"
        icon="Award"
        label={
          auditor?.auditsTotal !== 0
            ? `${auditor.auditsTotal} ${tryPluralizeWord('audit', auditor?.auditsTotal)} on Trustblock`
            : 'No audits published yet'
        }
      />
      {hasPublishedAudits && <TagCategories projectsCount={auditor.projectsCount} max={5} />}
      {isForHomepage && <div />}
    </Link>
  );
}
