import { zodResolver } from '@hookform/resolvers/zod';
import Bubble from '@trustblock/components/standalone/Bubble/Bubble';
import InputNumber from '@trustblock/components/standalone/Inputs/InputNumber/InputNumber';
import InputSelect from '@trustblock/components/standalone/Inputs/InputSelect/InputSelect';
import InputText from '@trustblock/components/standalone/Inputs/InputText/InputText';
import Pagination from '@trustblock/components/standalone/Pagination/Pagination';
import { chainsData } from '@trustblock/constants/contract.constants';
import { Chain, chains as chainsOptions } from '@trustblock/types/contract.types';
import { tagsData } from '@trustblock/constants/global.constants';
import useSearchAuditors from '@trustblock/web/hooks/auditor/useSearchAuditors';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SearchAuditorsResponse } from '@trustblock/types/auditor.types';
import { searchAuditorsValidator } from '@trustblock/validators/auditor.validators';
import AuditorMini from '@trustblock/components/global/AuditorMini/AuditorMini';
import { z } from 'zod';
import { tagsValidator } from '@trustblock/validators/global.validators';
import Form from '@trustblock/components/standalone/Form/Form';
import Button from '@trustblock/components/standalone/Button/Button';
import { nullsToUndefined } from '@trustblock/api/utils/dataTransformers';
import { useSearchParams } from 'next/navigation';
import { Tag } from '@trustblock/types/global.types';
import styles from './HomePage.module.scss';

const searchAuditorsLocalValidator = searchAuditorsValidator
  .extend({
    chains: z
      .array(z.enum(chainsOptions as [Chain, ...Chain[]]))
      .optional()
      .nullable(),
    tags: tagsValidator.optional().nullable()
  })
  .omit({ page: true });
export type SearchAuditorsLocalParams = z.infer<typeof searchAuditorsLocalValidator>;

interface HomePageAuditorsProps {
  auditorsSearch?: SearchAuditorsResponse;
  onChange: (data: SearchAuditorsLocalParams & { page: number }) => void;
  isActive: boolean;
}

export default function HomePageAuditors({ auditorsSearch, onChange, isActive }: HomePageAuditorsProps) {
  const query = useSearchParams();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<SearchAuditorsLocalParams>({
    mode: 'onTouched',
    resolver: zodResolver(searchAuditorsLocalValidator)
  });
  const { searchAuditors, auditors, currentPage, setCurrentPage, totalPages, isRequesting } = useSearchAuditors({
    initialData: auditorsSearch
  });

  useEffect(() => {
    if (!query) return;

    if (query.get('page')) setCurrentPage(Number(query.get('page')));
    const chains = query.get('chains') ?? undefined;
    setValue('chains', chains ? (chains.split(',') as Chain[]) : undefined);
    const tags = query.get('tags') ?? undefined;
    setValue('tags', tags ? (tags.split(',') as Tag[]) : undefined);
    const tvs = query.get('tvs') ?? undefined;
    setValue('tvs', tvs);
  }, [query, setValue, setCurrentPage]);

  const onChangeWrapper = (newPage?: number) => {
    const dataToSend = nullsToUndefined({
      ...nullsToUndefined(getValues()),
      page: newPage ?? currentPage
    });
    onChange(dataToSend);
  };

  const setCurrentPageWrapper = async (page: number) => {
    setCurrentPage(page);
    await searchAuditors({ page, ...nullsToUndefined(getValues()) });
    onChangeWrapper(page);
  };

  const search = handleSubmit(async (data) => {
    await searchAuditors(nullsToUndefined(data));
    onChangeWrapper();
  });

  return (
    <section className={classNames(styles.homePageSection, { [styles.homePageSectionActive]: isActive })}>
      <Form className={styles.homePageSearchAuditor} onSubmit={search}>
        <div>
          <InputSelect
            icon="Server"
            control={control}
            placeholder="Chains"
            fieldName="chains"
            isMulti
            options={chainsOptions.map((chain) => ({
              value: chain,
              label: chainsData[chain].label,
              icon: chainsData[chain].icon
            }))}
            error={errors?.chains?.message}
          />
          <InputSelect
            icon="Tags"
            control={control}
            placeholder="Tags"
            fieldName="tags"
            isMulti
            options={Object.values(tagsData)}
            error={errors?.tags?.message}
          />
          <InputNumber
            placeholder="Liquidity"
            icon="DollarSign"
            register={register}
            fieldName="tvs"
            onlyInteger
            onlyPositive
            valueAsNumber={false}
            error={errors?.tvs?.message}
            validationRules={undefined}
          />
          <InputText
            icon="Search"
            fieldName="name"
            placeholder="Name"
            register={register}
            error={errors?.name?.message}
          />
          <Button onlyIcon icon="Search" type="submit" mode="primary" fill loading={isRequesting} />
        </div>
      </Form>
      <div
        className={classNames(styles.homePageResult, styles.homePageResultAuditor)}
        data-testid="homepage-auditors-results"
      >
        {auditors && auditors?.length !== 0 ? (
          auditors.map((auditor) => (
            <AuditorMini key={auditor?.slug} auditor={auditor} isForHomepage data-testid="homepage-auditor-mini" />
          ))
        ) : (
          <Bubble data-testid="homepage-no-auditors" fill header="No auditors found." icon="Info" />
        )}
      </div>
      <div className={styles.homePagePagination}>
        <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={setCurrentPageWrapper} />
      </div>
    </section>
  );
}
