import Icon from '@trustblock/components/standalone/Icon/Icon';
import Tag, { TagColor } from '@trustblock/components/standalone/Tag/Tag';
import { ProjectsCount } from '@trustblock/types/project.types';
import { tagsData } from '@trustblock/constants/global.constants';
import styles from './TagCategories.module.scss';

interface TagCategoriesProps {
  projectsCount: ProjectsCount;
  color?: TagColor;
  max?: number;
}

type ProjectCountKey = keyof Omit<ProjectsCount, 'total'>;

export default function TagCategories({ projectsCount, color = 'grey2', max }: TagCategoriesProps) {
  const categories = Object.keys(projectsCount)
    .filter((key) => key !== 'total' && projectsCount[key as ProjectCountKey] > 0)
    .sort((a, b) => projectsCount[b as ProjectCountKey] - projectsCount[a as ProjectCountKey]) as ProjectCountKey[];

  const categoriesIcons = categories.map((tag) => tagsData[tag].icon);

  if (max && categories.length > max) {
    return (
      <Tag className={styles.tbTagCategoriesSingle} color={color} icon="Server" label={`${categories.length} tags`} />
    );
  }

  if (categoriesIcons.length === 1)
    return (
      <Tag
        className={styles.tbTagCategoriesSingle}
        color={color}
        icon={categoriesIcons[0]}
        label={tagsData[categories[0]].label}
      />
    );

  return (
    <Tag color={color}>
      <div className={styles.tbTagCategoriesMulti}>
        {categoriesIcons.map((icon) => (
          <Icon key={icon} name={icon} />
        ))}
      </div>
    </Tag>
  );
}
