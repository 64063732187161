import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { SearchAuditorsResponse } from '@trustblock/types/auditor.types';
import { SearchProjectsResponse } from '@trustblock/types/project.types';
import { useSearchParams } from 'next/navigation';
import { nullsToUndefined } from '@trustblock/api/utils/dataTransformers';
import { cleanObject } from '@trustblock/helpers/formatters';
import styles from './HomePage.module.scss';
import HomePageAuditors, { SearchAuditorsLocalParams } from './HomePageAuditors';
import HomePageProjects, { SearchProjectsParamsLocal } from './HomePageProjects';

interface HomePageProps {
  auditorsSearch?: SearchAuditorsResponse;
  projectsSearch?: SearchProjectsResponse;
}

export type SearchTab = 'projects' | 'auditors';

function HomePage({ auditorsSearch, projectsSearch }: HomePageProps) {
  const query = useSearchParams();
  const [searchTab, setSearchTab] = useState<SearchTab>((query?.get('tab') as SearchTab) ?? 'projects');

  useEffect(() => {
    if (!query) return;

    const tab = query.get('tab') as SearchTab;
    if (tab) setSearchTab(tab);
  }, [query]);

  const updateQueryParams = (
    queryParameters: { tab?: SearchTab } & { page?: number } & (SearchProjectsParamsLocal | SearchAuditorsLocalParams)
  ) => {
    const finalTab = queryParameters.tab ?? searchTab;
    const page = queryParameters.page ?? 1;
    const queryParams = cleanObject<string>(nullsToUndefined({ tab: finalTab, ...queryParameters, page }));
    const queryString = new URLSearchParams(queryParams).toString();
    window.history.replaceState(null, '', `?${queryString}`);
  };

  const setSearchTabWrapper = (tab: 'projects' | 'auditors') => {
    setSearchTab(tab);
    updateQueryParams({ tab });
  };

  return (
    <div className={styles.homePage}>
      <div className={classNames(styles.homePageContainer, 'max-content-width')}>
        <h2 className={styles.homePageContainerTitle}>Browse web3 security now.</h2>
        <div className={styles.homePageContainerTabs}>
          <h5
            role="button"
            tabIndex={0}
            onClick={() => setSearchTabWrapper('projects')}
            className={classNames({
              [styles.homePageContainerTabsActive]: searchTab === 'projects'
            })}
          >
            Search for a protocol&apos;s security status
          </h5>
          <h5
            data-testid="homepage-auditor-tab"
            onClick={() => setSearchTabWrapper('auditors')}
            className={classNames({
              [styles.homePageContainerTabsActive]: searchTab === 'auditors'
            })}
          >
            Find the right auditor for your project
          </h5>
        </div>

        <HomePageProjects
          projectsSearch={projectsSearch}
          onChange={updateQueryParams}
          isActive={searchTab === 'projects'}
        />
        <HomePageAuditors
          auditorsSearch={auditorsSearch}
          onChange={updateQueryParams}
          isActive={searchTab === 'auditors'}
        />
      </div>
    </div>
  );
}

export default HomePage;
