import classNames from 'classnames';
import { ReactNode } from 'react';
import Icon, { IconName } from '../Icon/Icon';
import styles from './Tag.module.scss';

export type TagColor = 'black' | 'grey' | 'grey2' | 'valid' | 'error' | 'warning' | 'warning2' | 'outline' | 'primary';

interface TagProps {
  label?: string;
  children?: ReactNode;
  icon?: IconName;
  color?: TagColor;
  withRemove?: boolean;
  className?: string;
  onClickRemove?: () => void;
}

function Tag({
  label,
  icon,
  color = 'black',
  withRemove = false,
  onClickRemove = () => {},
  children,
  className,
  ...props
}: TagProps) {
  return (
    <div
      {...props}
      className={classNames(styles.tbTag, className, styles[`tbTag${color.charAt(0).toUpperCase() + color.slice(1)}`], {
        [styles.tbTagOnlyIcon]: !label && icon
      })}
    >
      {icon && <Icon name={icon} />}
      {label ? <span>{label}</span> : children}
      {withRemove && (
        <button type="button" className={styles['tbTag-removeButton']} onClick={onClickRemove}>
          <Icon name="X" />
        </button>
      )}
    </div>
  );
}

export default Tag;
