import { fixedLengths, maxLengths, minLengths } from '@trustblock/constants/configuration.constants';
import { UserStatus, UserType, userStatuses, userTypes } from '@trustblock/types/user.types';
import { z } from 'zod';
import { linksValidator } from './global.validators';
import { testIsUrl } from './zodCustomValidators';

export const userValidator = z.object({
  id: z.string().uuid().length(fixedLengths.id),
  authId: z.string().length(fixedLengths.authId),
  status: z.enum(userStatuses as [UserStatus, ...UserStatus[]]),
  slug: z.string().min(minLengths.slug).max(maxLengths.slug),
  name: z.string().min(minLengths.name).max(maxLengths.name),
  profilePictureUrl: z.string().url().optional().superRefine(testIsUrl),
  description: z.string().min(minLengths.description).max(maxLengths.description),
  type: z.enum(userTypes as [UserType, ...UserType[]]),
  email: z.string().email(),
  apiKey: z.string().length(fixedLengths.apiKey),
  createdAt: z.date()
});
export type UserValidator = z.infer<typeof userValidator>;

export const createUserValidator = userValidator
  .omit({
    id: true,
    apiKey: true,
    type: true,
    slug: true,
    profilePictureUrl: true,
    createdAt: true,
    status: true
  })
  .extend({ links: linksValidator.omit({ targetId: true }) });

export const getUserValidator = userValidator.pick({ slug: true });

export const updateUserValidator = userValidator
  .omit({
    id: true,
    apiKey: true,
    type: true,
    slug: true,
    profilePictureUrl: true,
    createdAt: true,
    email: true,
    authId: true,
    status: true
  })
  .extend({ links: linksValidator.omit({ targetId: true }) });
