import { fixedLengths, maxLengths, minLengths } from '@trustblock/constants/configuration.constants';
import {
  AuditorHeadcount,
  AuditorType,
  ProspectChain,
  auditorHeadcounts,
  auditorTypes,
  prospectChains
} from '@trustblock/types/auditor.types';
import { z } from 'zod';
import { Tag, tags } from '@trustblock/types/global.types';
import { Chain, chains } from '@trustblock/types/contract.types';
import { createUserValidator, updateUserValidator, userValidator } from './user.validators';
import { testIsUrl, testIsValidYear } from './zodCustomValidators';
import { linksValidator, paginationValidator } from './global.validators';

export const auditorValidator = z.object({
  userId: z.string().uuid().length(fixedLengths.id),
  type: z.enum(auditorTypes as [AuditorType, ...AuditorType[]]),
  foundingYear: z.number().optional().superRefine(testIsValidYear),
  headcount: z.enum(auditorHeadcounts as [AuditorHeadcount, ...AuditorHeadcount[]]).optional(),
  isInactive: z.boolean()
});
export type AuditorValidator = z.infer<typeof auditorValidator>;

export const auditorMetricsValidator = z.object({
  auditorId: z.string().uuid().length(fixedLengths.id),
  evaluatedAt: z.date()
});
export type AuditorMetricsValidator = z.infer<typeof auditorMetricsValidator>;

export const auditorMetricsStatsSubValidator = z.object({
  auditorMetricsStatsSubId: z.string().uuid().length(fixedLengths.id),
  total: z.number(),
  average: z.number(),
  min: z.number(),
  max: z.number()
});
export type AuditorMetricsStatsSubValidator = z.infer<typeof auditorMetricsStatsSubValidator>;

export const auditorMetricsStatsValidator = z.object({
  auditorMetricsId: z.string().uuid().length(fixedLengths.id),
  valueMetricsId: z.string().uuid().length(fixedLengths.id),
  usersMetricsId: z.string().uuid().length(fixedLengths.id),
  transactionsMetricsId: z.string().uuid().length(fixedLengths.id)
});
export type AuditorMetricsStatsValidator = z.infer<typeof auditorMetricsStatsValidator>;

export const createAuditorValidator = z
  .object({
    lastName: z.string(),
    firstName: z.string().min(1),
    jobTitle: z.string().min(1),
    email: z.string().email(),
    reportsHistoryUrl: z.string().url().superRefine(testIsUrl)
  })
  .merge(
    auditorValidator
      .pick({ type: true })
      .merge(createUserValidator)
      .extend({
        prospectChains: z.array(z.enum(prospectChains as [ProspectChain, ...ProspectChain[]])).min(1),
        links: linksValidator.omit({ targetId: true })
      })
  );
export type CreateAuditorParams = z.infer<typeof createAuditorValidator>;

export const verifyAuditorValidator = userValidator.pick({ slug: true });
export type VerifyAuditorParams = z.infer<typeof verifyAuditorValidator>;

export const updateAuditorValidator = updateUserValidator.merge(
  auditorValidator.omit({ userId: true, isInactive: true })
);
export type UpdateAuditorParams = z.infer<typeof updateAuditorValidator>;

export const searchAuditorsValidator = z
  .object({
    chains: z
      .string()
      .transform((value) => value.split(',') as Chain[])
      .refine((value) => value.length > 0 && value.every((chain) => chains.includes(chain)), {
        message: `Please provide one of the following chains: ${chains.join(', ')}`
      })
      .optional(),
    tags: z
      .string()
      .transform((value) => value.split(',') as Tag[])
      .refine((value) => value.length > 0 && value.every((tag) => tags.includes(tag as Tag)), {
        message: `Please provide one of the following tags: ${tags.join(', ')}`
      })
      .optional(),
    tvs: z.string().regex(/^\d+$/).optional(),
    name: z.string().min(1).optional()
  })
  .merge(paginationValidator);
export type SearchAuditorsParams = z.infer<typeof searchAuditorsValidator>;

export const getAuditorBySlugValidator = z.object({
  slug: z.string().min(minLengths.slug).max(maxLengths.slug)
});
