import HomePage from '@trustblock/web/components/HomePage/HomePage';
import Head from 'next/head';
import React from 'react';
import { SearchAuditorsResponse } from '@trustblock/types/auditor.types';
import { SearchProjectsResponse } from '@trustblock/types/project.types';
import { apiRoutes, requestApi } from '@trustblock/web/helpers/api';

interface HomeProps {
  auditors?: SearchAuditorsResponse;
  projects?: SearchProjectsResponse;
}

function Home({ auditors, projects }: HomeProps) {
  return (
    <>
      <Head>
        <title>Trustblock | Home page</title>
      </Head>
      <div className="page page-no-gutter-bottom">
        <HomePage auditorsSearch={auditors} projectsSearch={projects} />
      </div>
    </>
  );
}

export async function getStaticProps() {
  const props: { auditors: SearchAuditorsResponse | null; projects: SearchProjectsResponse | null } = {
    auditors: null,
    projects: null
  };

  try {
    const auditorsResponse = await requestApi<'searchAuditors'>({
      route: apiRoutes.searchAuditors(),
      data: { page: '1' }
    });
    props.auditors = auditorsResponse.data;
  } catch (err) {
    props.auditors = null;
  }
  try {
    const projectsResponse = await requestApi<'searchProjects'>({
      route: apiRoutes.searchProjects(),
      data: { page: '1' }
    });

    props.projects = projectsResponse.data;
  } catch (err) {
    props.projects = null;
  }

  return {
    props,
    revalidate: 10
  };
}

export default Home;
