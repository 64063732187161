/* eslint-disable no-continue */
import classNames from 'classnames';
import React, { useMemo } from 'react';
import generateRenderKey from '@trustblock/helpers/generateRenderKey';
import Icon from '../Icon/Icon';
import styles from './Pagination.module.scss';

interface PaginationProps {
  currentPage?: number;
  totalPages: number;
  onChangePage: (page: number) => void;
  className?: string;
}

function getPaginationItems(currentPage: number, lastPage: number, maxLength: number) {
  const items: (number | string)[] = [];

  // handle lastPage less than maxLength
  if (lastPage <= maxLength) {
    for (let i = 1; i <= lastPage; i += 1) {
      items.push(i);
    }
  }

  // handle ellipsis logics
  else {
    const firstPage = 1;
    const confirmedPagesCount = 3;
    const deductedMaxLength = maxLength - confirmedPagesCount;
    const sideLength = deductedMaxLength / 2;

    // handle ellipsis in the middle
    if (currentPage - firstPage < sideLength || lastPage - currentPage < sideLength) {
      for (let j = 1; j <= sideLength + firstPage; j += 1) {
        items.push(j);
      }

      items.push('ellipsis-1');

      for (let k = lastPage - sideLength; k <= lastPage; k += 1) {
        items.push(k);
      }
    }

    // handle two ellipsis
    else if (currentPage - firstPage >= deductedMaxLength && lastPage - currentPage >= deductedMaxLength) {
      const deductedSideLength = sideLength - 1;

      items.push(1);
      items.push('ellipsis-1');

      for (let l = currentPage - deductedSideLength; l <= currentPage + deductedSideLength; l += 1) {
        items.push(l);
      }

      items.push('ellipsis-2');
      items.push(lastPage);
    }

    // handle ellipsis not in the middle
    else {
      const isNearFirstPage = currentPage - firstPage < lastPage - currentPage;
      let remainingLength = maxLength;

      if (isNearFirstPage) {
        for (let m = 1; m <= currentPage + 1; m += 1) {
          items.push(m);
          remainingLength -= 1;
        }

        items.push('ellipsis-1');
        remainingLength -= 1;

        for (let n = lastPage - (remainingLength - 1); n <= lastPage; n += 1) {
          items.push(n);
        }
      } else {
        for (let o = lastPage; o >= currentPage - 1; o -= 1) {
          items.unshift(o);
          remainingLength -= 1;
        }

        items.unshift('ellipsis-2');
        remainingLength -= 1;

        for (let p = remainingLength; p >= 1; p -= 1) {
          items.unshift(p);
        }
      }
    }
  }

  return items;
}

function Pagination({ currentPage = 1, totalPages, onChangePage, className }: PaginationProps) {
  const paginationButtonsToShow = useMemo(
    () => getPaginationItems(currentPage, totalPages, 7),
    [totalPages, currentPage]
  );

  const isOnLastPage = currentPage === totalPages || totalPages === 0;
  const isOnFirstPage = currentPage === 1 || totalPages === 0;

  return (
    <div className={`${styles.tbPagination} ${className}`}>
      <button
        type="button"
        onClick={() => {
          onChangePage(currentPage - 1);
        }}
        disabled={isOnFirstPage}
        className={styles.tbPaginationButton}
      >
        <Icon name="ArrowLeft" />
      </button>
      {paginationButtonsToShow.map((page, index) =>
        typeof page === 'string' ? (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={page}
            type="button"
            className={styles.tbIndexButton}
            onClick={() => {
              const previousPage = paginationButtonsToShow[index - 1];
              const nextPage = paginationButtonsToShow[index + 1];
              const newPage = Math.floor(((previousPage as number) + (nextPage as number)) / 2);
              onChangePage(newPage);
            }}
          >
            ...
          </button>
        ) : (
          <button
            key={generateRenderKey()}
            type="button"
            className={classNames(styles.tbIndexButton, {
              [styles.tbIndexButtonActive]: page === currentPage
            })}
            onClick={() => {
              onChangePage(page);
            }}
          >
            {page}
          </button>
        )
      )}
      {/* {paginationIndexes} */}
      <button
        type="button"
        onClick={() => {
          onChangePage(currentPage + 1);
        }}
        disabled={isOnLastPage}
        className={styles.tbPaginationButton}
      >
        <Icon name="ArrowRight" />
      </button>
    </div>
  );
}

export default Pagination;
