import Icon from '@trustblock/components/standalone/Icon/Icon';
import Tag, { TagColor } from '@trustblock/components/standalone/Tag/Tag';
import { chainsData } from '@trustblock/constants/contract.constants';
import { Chain, ContractsCount } from '@trustblock/types/contract.types';
import styles from './TagChains.module.scss';

interface TagChainsProps {
  contractsCount: ContractsCount;
  color?: TagColor;
  max?: number;
}

export default function TagChains({ contractsCount, color = 'grey2', max }: TagChainsProps) {
  const chains = Object.keys(contractsCount)
    .filter((key) => key !== 'total' && contractsCount[key as Chain] > 0)
    .sort((a, b) => contractsCount[b as Chain] - contractsCount[a as Chain]) as (keyof Omit<
    typeof contractsCount,
    'total'
  >)[];

  const chainsIcons = chains.map((chain) => chainsData[chain].icon);

  if (max && chains.length > max) {
    return <Tag className={styles.tbTagChainsSingle} color={color} icon="Server" label={`${chains.length} chains`} />;
  }

  if (chainsIcons.length === 1)
    return (
      <Tag
        className={styles.tbTagChainsSingle}
        color={color}
        icon={chainsIcons[0]}
        label={chainsData[chains[0]].label}
      />
    );

  return (
    <Tag color={color}>
      <div className={styles.tbTagChainsMulti}>
        {chainsIcons.map((icon) => (
          <Icon key={icon} name={icon} />
        ))}
      </div>
    </Tag>
  );
}
